import "./RakaaPage.scss";
import Footer from "../../components/layout/Footer";
import { SLIDES } from "../../constants/salat.constants.ts";
import back from "../../assets/salahIcons/ButtonLeft.svg";
import back2 from "../../assets/salahIcons/ButtonLeft2.svg";
import forward from "../../assets/salahIcons/ButtonRight.svg";
import React, { useState } from "react";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";

const RakaaPage = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const navigate = useNavigate();
  const nextSlide = () => {
    setCurrentSlide((prev) => {
      const next = prev === SLIDES.length - 1 ? 0 : prev + 1;

      return next;
    });
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => {
      if (prev > 0) {
        return prev - 1; // Go to the previous slide
      } else {
        return prev; // Stay at the first slide
      }
    });
  };

  const getPrevIndex = () => {
    return (currentSlide - 1 + SLIDES.length) % SLIDES.length;
  };

  const getNextIndex = () => {
    return (currentSlide + 1) % SLIDES.length;
  };

  return (
    <div className="pages-position">
      <div className="pages-header">
        <FontAwesomeIcon
          icon={faArrowLeft}
          className="icon cursor-pointer"
          onClick={() => {
            navigate(-1);
          }}
        />
        <span className="title-page">Apprendre Rakaa</span>
      </div>
      <div className="rakaa-body">
        <div className="flex flex-col justify-center items-center">
          <div className="all flex items-center max-w-screen-xl">
            {/* Previous Slide */}
            <div
              className={`card-prayerLeft p-4 w-1/4 text-center flex flex-col ${
                currentSlide ? "opacity-100" : "opacity-60"
              }`}
            >
              <img
                src={SLIDES[getPrevIndex()].image}
                alt="previous slide"
                className="mx-auto w-40 h-40"
              />
              <p className="special object-cover text-sm text-gray-600">
                {SLIDES[getPrevIndex()].description}
              </p>
            </div>

            {/* Left button */}
            <div>
              <button
                onClick={prevSlide}
                disabled={currentSlide === 0}
                className="abolute w-10 h-20 m-8 mt-16 p-2"
              >
                <img src={currentSlide === 0 ? back : back2} alt="back" />
              </button>
            </div>

            {/* Current Slide */}
            <div className="current mt-32 mb-20 flex flex-col rakaa-selected w-1/2 text-center">
              <img
                src={SLIDES[currentSlide].image}
                alt="current slide"
                className="mx-auto w-60 h-60"
              />
              <p className="text-gray-600 font-semibold">
                {SLIDES[currentSlide].description}
              </p>
            </div>

            {/* Right button */}
            <div>
              <button
                onClick={nextSlide}
                className="abolute m-8 mt-16 p-2 w-10 h-20 m-5"
              >
                <img src={forward} alt="forward"></img>
              </button>
            </div>

            {/* Next Slide */}
            <div className="card-prayerRight flex flex-col p-4 w-1/5 text-center">
              <img
                src={SLIDES[getNextIndex()].image}
                alt="next slide"
                className="mx-auto w-40 h-40"
              />
              <p className="special text-sm text-gray-600">
                {SLIDES[getNextIndex()].description}
              </p>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default RakaaPage;
