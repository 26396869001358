import "../../index.scss";
import Footer from "../../components/layout/Footer.jsx";
import { PRAYER_Image } from "../../constants/salat.constants.ts";
import Sort from "../../assets/salahIcons/SortRight.svg";
import myIcon from "../../assets/salahIcons/MosqueBG.svg";
import React from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";

function DetailSalahPage() {
  const { prayer } = useParams();
  const pray = PRAYER_Image.find(
    (p) => p.name.toLowerCase() === prayer.toLowerCase()
  );
  const navigate = useNavigate();

  return (
    <div className="pages-position">
      <div className="pages-header">
        <FontAwesomeIcon
          icon={faArrowLeft}
          className="icon cursor-pointer"
          onClick={() => {
            navigate(-1);
          }}
        />
        <span className="title-page">Salat Apprentissage</span>
      </div>

      <div className="relative flex-1 flex justify-center items-center">
        <div className="flex flex-col lg:flex-row md:flex-row max-w-6xl lg:space-x-16 w-full p-8">
          {/* title2+paragraphe */}
          <div className="flex-1 justify-start m-5 mb-0">
            <h1 className="font-bold text-gray-800 mb-8">Apprendre Salat</h1>
            <p className="text-justify text-gray-600">{pray.description}</p>
          </div>

          {/* Mosque SVG */}
          <div className="relative flex-1 flex justify-center items-center">
            <img
              className="absolute mb-6 fixed w-full max-w-[32em] min-w-[32em] h-auto"
              src={myIcon}
              alt="My Icon"
            />

            {/*  Image */}
            <div className="relative flex flex-col items-center">
              <img
                src={pray.image}
                alt={pray.name}
                className=" object-cover mt-32"
                style={{ width: "8em" }}
              />

              {/*  prayername */}
              <h2 className="text-3xl font-semibold text-gray-800 mt-8">
                {pray.name}
              </h2>
              <Link to={`/learn/salat/rakaa`}>
                {/* Button */}
                <button className="mt-7 ml-4 inline-flex items-center bg-[#ECE0B0] bg-opacity-30 text-[#817850] hover:bg-[#c4bc98] border border-[#817850] font-bold py-2 px-4 rounded-lg">
                  Apprendre le rakaa
                  <img src={Sort} alt="rakaa" className="ml-3 w-5 h-5" />
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default DetailSalahPage;
