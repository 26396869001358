import { useTranslation } from "react-i18next";
import { Language } from "../../enums/Language.ts";
import { useEffect, useState } from "react";
import "./NavBar.scss";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import logo from "../../assets/logos/logo.svg";

const NavBar = () => {
  const location = useLocation();
  const [pathName, setPathName] = useState("/");
  const [navOpened, setNavOpened] = useState(false);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [lang, setLang] = useState(i18n.language);

  const toggleNavBar = () => {
    setNavOpened(!navOpened);
  };
  useEffect(() => {
    setPathName(location.pathname);
  }, [location]);

  useEffect(() => {
    const tham = document.querySelector(".tham");
    tham &&
      tham.addEventListener("click", (_ev) => {
        tham.classList.toggle("tham-active");
      });
  }, []);

  const changeLanguage = (language) => {
    if (language) {
      setLang(language);
      i18n.changeLanguage(language);
    }
  };

  const Links = () => {
    return (
      <ul className="flex links-nav">
        <li className="m-1">
          <NavLink to="/">{t("links.HOME")}</NavLink>
        </li>
        <li className="m-1">
          <a href="/#services">{t("links.SERVICES")}</a>
        </li>
        <li className="m-1">
          <NavLink to="/quran">{t("links.QURAN")}</NavLink>
        </li>
        <li className="m-1">
          <NavLink to="/mosque">{t("links.MOSQUE")}</NavLink>
        </li>
        <li className="m-1">
          <NavLink to="/dhikr">{t("links.DHIKR")}</NavLink>
        </li>

        <li className="m-1 apprendre-link">
          <span
            className={
              pathName &&
              (pathName.startsWith("/list-sheikh") ||
                pathName.startsWith("/prophets-stories") ||
                pathName.startsWith("/names-of-allah"))
                ? "active"
                : ""
            }
          >
            {t("links.RESOURCES")}
          </span>
          <ResourcesSelectList></ResourcesSelectList>
        </li>
        <li className="m-1 apprendre-link">
          <span
            className={
              pathName && pathName.startsWith("/learn") ? "active" : ""
            }
          >
            {t("links.LEARN")}
          </span>
          <LearnSelectList></LearnSelectList>
        </li>
        <li className="m-1">
          <NavLink to="/prayer-times">{t("links.SALAT")}</NavLink>
        </li>
        <li className="m-1">
          <NavLink to="/zakat-calculator">{t("links.ZAKAT")}</NavLink>
        </li>

        <li className="m-1">
          <NavLink to="/about">{t("links.ABOUT")}</NavLink>
        </li>
        <li className="m-1">
          <NavLink to="/contact">{t("links.CONTACT")}</NavLink>
        </li>
        <li className="m-1 language-link">
          <span> {t(`common.${lang.toUpperCase()}`)}</span>
          <div className="drop-down-learn">
            <ul style={{ top: "0.8rem", padding: "0 0 0.5rem 0" }}>
              {lang !== Language.FR && (
                <li onClick={() => changeLanguage(Language.FR)}>
                  <span> {t("common.FR")}</span>
                </li>
              )}
              {lang !== Language.EN && (
                <li onClick={() => changeLanguage(Language.EN)}>
                  <span> {t("common.EN")}</span>
                </li>
              )}
              {lang !== Language.AR && (
                <li onClick={() => changeLanguage(Language.AR)}>
                  <span> {t("common.AR")}</span>
                </li>
              )}
            </ul>
          </div>
        </li>
      </ul>
    );
  };
  return (
    <nav className={"nav-bar p-2 " + (pathName === "/" ? "home" : "not-home")}>
      <div
        className="flex items-center"
        onClick={() => navigate("/")}
        style={{ cursor: "pointer" }}
      >
        <img src={logo} className=""></img>
        <span className="app-name">Muslim community </span>
      </div>
      {/* large screen */}
      <div className="nav-large-screen">
        <Links></Links>
      </div>

      {/* small screen */}
      <div className="nav-small-screen">
        <div className="tham tham-e-squeeze tham-w-6" onClick={toggleNavBar}>
          <div className="tham-box">
            <div
              className={"tham-inner " + (pathName === "/" ? "bg-white" : "")}
            />
          </div>
        </div>
        {navOpened && (
          <div className="nav-small-screen-opened">
            <Links></Links>
          </div>
        )}
      </div>
    </nav>
  );
};

const LearnSelectList = () => {
  const { t } = useTranslation();
  return (
    <div className="drop-down-learn">
      <ul>
        <li className="m-1">
          <NavLink to="/learn/wudhu">{t("learn.WUDHU.TITLE")}</NavLink>
        </li>
        <li className="m-1">
          <NavLink to="/learn/salat">{t("learn.SALAT.TITLE")}</NavLink>
        </li>
        <li className="m-1">
          <NavLink to="/learn/hajj">{t("learn.HAJJ.TITLE")}</NavLink>
        </li>
      </ul>
    </div>
  );
};

const ResourcesSelectList = () => {
  const { t } = useTranslation();
  return (
    <div className="drop-down-learn">
      <ul>
        <li className="m-1">
          <NavLink to="/names-of-allah">{t("links.ALLAH_NAMES")}</NavLink>
        </li>
        <li className="m-1">
          <NavLink to="/prophets-stories">
            {t("links.PROPHETS_STORIES")}
          </NavLink>
        </li>
        <li className="m-1">
          <NavLink to="/list-sheikh">{t("links.SHEIKHS")}</NavLink>
        </li>
        <li className="m-1">
          <NavLink to="/hadiths-prophet">{t("links.AHADITH")}</NavLink>
        </li>
      </ul>
    </div>
  );
};

export default NavBar;
