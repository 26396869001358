import Footer from "../../components/layout/Footer";
import { PRAYER_Image } from "../../constants/salat.constants.ts";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import "./SalahPage.scss";

function SalahPage() {
  const navigate = useNavigate();
  const [hovered, setHovered] = useState(null);

  return (
    <div className="pages-position">
      <div className="pages-header">
        <FontAwesomeIcon
          icon={faArrowLeft}
          className="icon cursor-pointer"
          onClick={() => {
            navigate(-1);
          }}
        />
        <span className="title-page">Salat Apprentissage</span>
      </div>

      <h1 className="font-bold text-blackBlue min-700-screen:mb-8 p-4 mx-10 min-700-screen:mx-20 mt-10">
        Apprendre 5 Salat
      </h1>
      <div className="flex flex-col items-center justify-center">
        <p className="text-justify px-4 text-center mb-10 min-700-screen:mb-20 mx-10 min-700-screen:mx-20">
          La salat est l'un des cinq piliers de l'Islam. Chaque musulman doit
          effectuer cinq prières quotidiennes, appelées faridah. Avant
          d'accomplir sa salât, le musulman doit pratiquer des ablutions pour se
          purifier. La prière s'effectue tourné vers la Kaaba .le prieur doit
          exprimer l'intention qui l'a conduit à la prière explicitement
          (niyah). La salât doit s'effectuer cinq fois dans la journée
        </p>

        {/* image+name */}
        <div className="prayers-list">
          {PRAYER_Image.map((prayer, index) => (
            <Link
              key={index}
              to={`${prayer.name.toLowerCase()}`}
              onMouseEnter={() => setHovered(index)}
              onMouseLeave={() => setHovered(null)}
              className="flex flex-col items-center justify-center prayer"
            >
              <img
                src={prayer.image}
                alt={prayer.name}
                className={`imageSalet duration-200 ${
                  hovered === index ? "scale-125" : "scale-100"
                }`}
              />
              <span className="mt-2 min-700-screen:mt-6 text-center">
                {prayer.name}
              </span>
            </Link>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default SalahPage;
