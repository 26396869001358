import step1S from "../assets/images/salat/1.png";
import step2S from "../assets/images/salat/2.png";
import step3S from "../assets/images/salat/3.png";
import step4S from "../assets/images/salat/4.png";
import step5S from "../assets/images/salat/5.png";
import step6S from "../assets/images/salat/6.png";
import step7S from "../assets/images/salat/7.png";
import step8S from "../assets/images/salat/8.png";
import step9S from "../assets/images/salat/9.png";
import step10S from "../assets/images/salat/10.png";
import step11S from "../assets/images/salat/11.png";
import step12S from "../assets/images/salat/12.png";
import step13S from "../assets/images/salat/13.png";

import fajrImage from "../assets/logos/prayer/fajr.png";
import dhuhrImage from "../assets/logos/prayer/dhuhr.png";
import asrImage from "../assets/logos/prayer/asr.png";
import maghribImage from "../assets/logos/prayer/maghrib.png";
import ishaImage from "../assets/logos/prayer/isha.png";

export const PRAYER_Image = [
  {
    name: "Fajr",
    image: fajrImage,
    description:
      "Lorem1 Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book Lorem6 Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
  },
  {
    name: "Dhuhr",
    image: dhuhrImage,
    description:
      "Lorem3 Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book Lorem6 Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
  },
  {
    name: "Asr",
    image: asrImage,
    description:
      "Lorem4 Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book Lorem6 Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book ",
  },
  {
    name: "Maghrib",
    image: maghribImage,
    description:
      "Lorem5 Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book Lorem6 Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
  },
  {
    name: "Isha",
    image: ishaImage,
    description:
      "Lorem6 Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book Lorem6 Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
  },
];

export const SLIDES = [
  {
    image: step1S,
    description:
      " 1: Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's t to make a type specimen book",
  },
  {
    image: step2S,
    description:
      " 2: Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's t to make a type specimen book",
  },
  {
    image: step3S,
    description:
      " 3: Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's t to make a type specimen book",
  },
  {
    image: step4S,
    description:
      " 4:Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's t to make a type specimen book",
  },
  {
    image: step5S,
    description:
      " 5: Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's t to make a type specimen book",
  },
  {
    image: step6S,
    description:
      " 6: Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's t to make a type specimen book",
  },
  {
    image: step7S,
    description:
      " 7: Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's t to make a type specimen book",
  },
  {
    image: step8S,
    description:
      " 8: Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's t to make a type specimen book",
  },
  {
    image: step9S,
    description:
      " 9:Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's t to make a type specimen book",
  },
  {
    image: step10S,
    description:
      " 10: Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's t to make a type specimen book",
  },
  {
    image: step11S,
    description:
      " 11: Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's t to make a type specimen book",
  },
  {
    image: step12S,
    description:
      " 12: Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's t to make a type specimen book",
  },
  {
    image: step13S,
    description:
      " 13: Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's t to make a type specimen book",
  },
];
