import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/home/Home";
import Error404 from "./pages/error404/Error404";
import ErrorPage from "./pages/errorPage/ErrorPage";
import NavBar from "./components/layout/NavBar";
import QuranPage from "./pages/quranPage/QuranPage";
import MosquePage from "./pages/mosquePage/MosquePage";
import SurahPage from "./pages/surahPage/SurahPage";
import ListAdhkarPage from "./pages/listAdhkarPage/ListAdhkarPage";
import { APIProvider } from "@vis.gl/react-google-maps";
import ConditionsGenerales from "./pages/conditionsGenerales/ConditionsGenerales";
import AboutUsPage from "./pages/aboutUsPage/AboutUsPage";
import DetailDhikrPage from "./pages/detailDhikrPage/DetailDhikrPage";
import PolitiqueConfidentialite from "./pages/politiqueConfidentialite/PolitiqueConfidentialite";
import ContactUsPage from "./pages/contactUsPage/ContactUsPage";
import { Toaster } from "react-hot-toast";
import ZakatPage from "./pages/zakatPage/ZakatPage";
import NamesOfAllah from "./pages/namesOfAllah/NamesOfAllah";
import PrayerTimesPage from "./pages/prayerTimesPage/PrayerTimesPage.tsx";
import ProphetsStoriesPage from "./pages/prophetsStoriesPage/ProphetsStoriesPage";
import StoryPage from "./pages/storyPage/StoryPage";
import ListSheikhPage from "./pages/listSheikhPage/ListSheikhPage";

import Chatbott from "./components/specific/chat_bot/Chatbott";
import SalahPage from "./pages/learnPage/SalahPage";
import RakaaPage from "./pages/learnPage/RakaaPage";
import DetailSalahPage from "./pages/learnPage/DetailSalahPage.jsx";
import WudhuPage from "./pages/learnPage/wudhu/WudhuPage.jsx";
import HajjPage from "./pages/learnPage/hajj/HajjPage.jsx";
import HadithsPage from "./pages/hadithsPage/HadithsPage.tsx";
import DetailsHadithPage from "./pages/detailsHadithPage/DetailsHadithPage.tsx";

function App() {
  return (
    <APIProvider
      apiKey={"AIzaSyATYxvLpHtisp-fr-1V-TjRnu7DHsAQJqc"}
      onLoad={() => console.log("Maps API has loaded.")}
      libraries={["places"]}
    >
      <Chatbott />
      <BrowserRouter>
        <NavBar />

        <Routes>
          <Route path="/" errorElement={<ErrorPage />} element={<Home />} />
          <Route
            path="/quran/*"
            errorElement={<ErrorPage />}
            element={<QuranRoutes />}
          />

          <Route
            path="/mosque"
            errorElement={<ErrorPage />}
            element={<MosquePage />}
          />
          <Route
            path="/dhikr/*"
            errorElement={<ErrorPage />}
            element={<AdhkarRoutes />}
          />
          <Route
            path="/learn/*"
            errorElement={<ErrorPage />}
            element={<LearnRoutes />}
          />
          <Route
            path="/prayer-times"
            errorElement={<ErrorPage />}
            element={<PrayerTimesPage />}
          />
          <Route
            path="/zakat-calculator"
            errorElement={<ErrorPage />}
            element={<ZakatPage />}
          />
          <Route
            path="/names-of-allah"
            errorElement={<ErrorPage />}
            element={<NamesOfAllah />}
          />
          <Route
            path="/prophets-stories/*"
            errorElement={<ErrorPage />}
            element={<ProphetsRoutes />}
          />
          <Route
            path="/list-sheikh"
            errorElement={<ErrorPage />}
            element={<ListSheikhPage />}
          />
          <Route
            path="/hadiths-prophet/*"
            errorElement={<ErrorPage />}
            element={<HadithRoutes />}
          />
          <Route
            path="/about"
            errorElement={<ErrorPage />}
            element={<AboutUsPage />}
          />
          <Route
            path="/contact"
            errorElement={<ErrorPage />}
            element={<ContactUsPage />}
          />
          <Route
            path="/conditions_generales"
            errorElement={<ErrorPage />}
            element={<ConditionsGenerales />}
          />
          <Route
            path="/politique_confidentialite"
            errorElement={<ErrorPage />}
            element={<PolitiqueConfidentialite />}
          />

          <Route path="*" element={<Error404 />} />
        </Routes>
        <Toaster />
      </BrowserRouter>
    </APIProvider>
  );
}

const QuranRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<QuranPage />} />
      <Route path=":numSorah" element={<SurahPage />} />
    </Routes>
  );
};

const LearnRoutes = () => {
  return (
    <Routes>
      {/* <Route path="" element={<LearnPage />} /> */}
      <Route
        path="salat/*"
        errorElement={<ErrorPage />}
        element={<SalatRoutes />}
      />
      <Route path="wudhu" element={<WudhuPage />} />
      <Route path="hajj" element={<HajjPage />} />
    </Routes>
  );
};

const AdhkarRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<ListAdhkarPage />} />
      <Route path=":titleDhikr" element={<DetailDhikrPage />} />
    </Routes>
  );
};

const ProphetsRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<ProphetsStoriesPage />} />
      <Route path=":nameProphet" element={<StoryPage />} />
    </Routes>
  );
};

const SalatRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<SalahPage />} />

      <Route
        path="rakaa"
        errorElement={<ErrorPage />}
        element={<RakaaPage />}
      />
      <Route path=":prayer" element={<DetailSalahPage />} />
    </Routes>
  );
};

const HadithRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<HadithsPage />} />
      <Route path=":hadith" element={<DetailsHadithPage />} />
    </Routes>
  );
};

export default App;
