import { useNavigate } from "react-router-dom";
import "./HajjPage.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

const HajjPage = () => {
  const navigate = useNavigate();
  return (
    <div className="pages-position">
      <div className="pages-header">
        <FontAwesomeIcon
          icon={faArrowLeft}
          className="icon cursor-pointer"
          onClick={() => {
            navigate(-1);
          }}
        />
        <span className="title-page">Apprendre Hajj</span>
      </div>
    </div>
  );
};

export default HajjPage;
